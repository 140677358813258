import { Component, EventEmitter, Input, Output, ViewChildren, QueryList, ElementRef } from '@angular/core';

@Component({
  selector: 'app-verification-code-input',
  templateUrl: './verification-code-input.component.html',
  styleUrls: ['./verification-code-input.component.scss']
})
export class VerificationCodeInputComponent {
  @Input('code-length') codeLength: number = 4; // Default code length
  @Input() separator: string = ''; // Default no separator
  @Output() codeComplete = new EventEmitter<string>();

  code: string[] = [];
  separatorIndex: number;

  @ViewChildren('codeInput') codeInputs!: QueryList<ElementRef>;

  constructor() {
    this.code = Array(this.codeLength).fill('');
    this.separatorIndex = this.codeLength / 2;
  }

  onInputChange(value: string, index: number): void {
    // Validate input is a single digit
    if (/^\d$/.test(value)) {
      this.code[index] = value;
      this.checkCodeCompletion();

      // Move to the next input if available and valid digit is entered
      if (index < this.codeLength - 1) {
        this.focusNextInput(index);
      }
    } else {
      this.code[index] = ''; // Clear invalid input
    }
  }

  focusNextInput(index: number): void {
    const nextIndex = index + 1;
    if (nextIndex < this.codeLength) {
      const nextInput = this.codeInputs.toArray()[nextIndex];
      if (nextInput) {
        nextInput.nativeElement.focus();
      }
    }
  }

  checkCodeCompletion(): void {
    if (this.code.every(char => char !== '' && char !== undefined && char !== null) && this.code.length === this.codeLength) {
      const codeString = this.code.join('');
      console.log(codeString);
      this.codeComplete.emit(codeString);
    }
  }

  onKeydown(event: KeyboardEvent, index: number): void {

    if (event.key === 'Backspace' && index > 0) {
      this.backSpace(index);
    }
  }

  backSpace(index: number): void {
    if (index > 0) {
      const currentInput = this.codeInputs.toArray()[index];
      if(currentInput.nativeElement.value !== '') {
        currentInput.nativeElement.value = '';
        this.code[index] = '';
        return;
      }
      const prevInput = this.codeInputs.toArray()[index - 1];
      if (prevInput) {
        setTimeout(() => {
          console.log(prevInput);
          prevInput.nativeElement.focus();
          prevInput.nativeElement.value = '';
          this.code[index] = '';
        });
      }
    }
  }
}
