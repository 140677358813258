import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toman'
})
export class TomanPipe implements PipeTransform {
  transform(value: number): string {
    const tomanValue = (value / 10).toLocaleString('fa-IR', { useGrouping: true });
    const formattedValue = tomanValue.replace(/٬/g, ',');
    return `${formattedValue} تومانءءء`;
  }
}
