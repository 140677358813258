<div class="relative">

  <label for="voice-search" class="sr-only">حرکت ورزشی...</label>
    <div class="relative w-full">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <i class="fa-duotone fa-dumbbell"></i>
        </div>
        <input [(ngModel)]="searchQuery"
        (click)="onClick()"
        (input)="onInput($event)" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="حرکت ورزشی را جستجو کنید..." required />
        <button type="button" class="absolute inset-y-0 end-0 flex items-center pe-3">
            <i class="fa-duotone fa-search"></i>
            <span class="sr-only">Search</span>
        </button>
    </div>

  <ul *ngIf="suggestions.length" class="absolute z-10 w-full bg-white border rounded-md shadow-lg mt-1 dark:bg-gray-700 dark:border-gray-600">
    <li
      *ngFor="let suggestion of suggestions"
      (click)="selectSuggestion(suggestion)"
      class="p-2 cursor-pointer hover:bg-gray-200 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white"
    >
      {{ 'workouts.' + suggestion.english | translate }}
    </li>
  </ul>
</div>
