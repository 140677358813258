<div class="relative w-32 h-32">
  <svg class="w-full h-full">
    <circle
      class="text-gray-300"
      stroke-width="5"
      stroke="currentColor"
      fill="transparent"
      r="50"
      cx="50%"
      cy="50%"
    />
    <circle
      class="progress-ring__circle text-blue-600"
      stroke-width="5"
      stroke="currentColor"
      fill="transparent"
      r="50"
      cx="50%"
      cy="50%"
      [attr.stroke-dasharray]="314"
      [attr.stroke-dashoffset]="strokeDashoffset"
    />
  </svg>
  <div class="absolute inset-0 flex items-center justify-center">
    <span id="progress-text" class="text-xl font-semibold text-black dark:text-white"><b>{{ progress | persian }}%</b></span>
  </div>
</div>
