<div class="verification-code-input" dir="ltr">
  <ng-container *ngFor="let item of [].constructor(codeLength); let i = index">
    <input
      #codeInput
      type="text"
      class="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
      maxlength="1"
      [ngModel]="code[i]"
      (ngModelChange)="onInputChange($event, i)"
      (keydown)="onKeydown($event, i)"
      pattern="\d*"
    />
    <!-- Add separator if specified and not the last element -->
    <span *ngIf="separator && i == separatorIndex">{{ separator }}</span>
  </ng-container>
</div>
