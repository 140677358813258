import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../layout/layout.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TomanPipe } from './pipes/toman.pipe';
import { PersianPipe } from './pipes/persian.pipe';
import { CountdownDirective } from './directives/countdown.directive';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { WorkoutAutoCompleteComponent } from './components/workout-auto-complete/workout-auto-complete.component';
import { PersianTranslatePipe } from './pipes/persian-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { SecondPipe } from './pipes/second.pipe';
import { EnumRadioButtonsComponent } from './components/enum-radio-buttons/enum-radio-buttons.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { EnumCheckboxComponent } from './components/enum-checkbox/enum-checkbox.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { VerificationCodeInputComponent } from './components/verification-code-input/verification-code-input.component';


@NgModule({
  declarations: [
    TomanPipe,
    PersianPipe,
    PersianTranslatePipe,
    SecondPipe,
    CountdownDirective,
    AutoCompleteComponent,
    WorkoutAutoCompleteComponent,
    EnumRadioButtonsComponent,
    ProgressBarComponent,
    EnumCheckboxComponent,
    VerificationCodeInputComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatSnackBarModule,
    MatButtonModule,
    MatRippleModule,
  ],
  exports: [
    LayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TomanPipe,
    PersianPipe,
    PersianTranslatePipe,
    SecondPipe,
    CountdownDirective,
    AutoCompleteComponent,
    WorkoutAutoCompleteComponent,
    EnumRadioButtonsComponent,
    ProgressBarComponent,
    EnumCheckboxComponent,
    VerificationCodeInputComponent,
    TranslateModule,
    MatSnackBarModule,
    MatButtonModule,
    MatRippleModule
  ]
})
export class SharedModule { }
