import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContainerComponent } from './container/container.component';
import { LayoutComponent } from './layout/layout.component';
import { RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { QuizLayoutComponent } from './quiz-layout/quiz-layout.component';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ContainerComponent,
    LayoutComponent,
    PublicLayoutComponent,
    QuizLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ContainerComponent,
    LayoutComponent,
    PublicLayoutComponent,
    QuizLayoutComponent
  ]
})
export class LayoutModule { }
