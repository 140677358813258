<ul class="grid w-full gap-2 {{ twoCols? 'grid-cols-2' : '' }} mb-8">
  <li *ngFor="let key of enumKeys">
    <input type="checkbox" [id]="'enum-' + key" name="enumCheckbox" [value]="enum[key]"
      class="hidden peer" (change)="onValueChange(key)"/>
    <label [for]="'enum-' + key" [class]="color + ' ' + imageClass ">
      <span>{{ 'enums.'+key.toLowerCase() | translate }}</span>
      <i *ngIf="selectedValues.indexOf(key) > -1" class="fa-duotone fa-check"></i>
      <img class="image-{{imageClass}}" *ngIf="hasImages" [src]="getImageUrl(key)" alt="{{ key }}" />
    </label>
  </li>
</ul>
