import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  progress = 0;
  strokeDashoffset = 314;

  @Output() progressComplete = new EventEmitter<void>();

  ngOnInit(): void {
    this.animateProgress();
  }

  animateProgress(): void {
    const duration = Math.random() * (2000) + 4000;
    const startTime = performance.now();

    const animate = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progressPercentage = Math.min((elapsedTime / duration) * 100, 100);
      this.progress = Math.round(progressPercentage);
      this.strokeDashoffset = 314 - (314 * progressPercentage) / 100;

      if (elapsedTime < duration) {
        requestAnimationFrame(animate);
      } else {
        this.progressComplete.emit();
      }
    };

    requestAnimationFrame(animate);
  }
}
