<ul class="grid w-full gap-2 {{ twoCols? 'grid-cols-2' : '' }}">
  <li *ngFor="let key of enumKeys">
    <input type="radio" [id]="'enum-' + key" name="enumRadio" [(ngModel)]="selectedValue" [value]="enum[key]"
      class="hidden peer" required (change)="valueChangedEffects(key)"/>
    <label [for]="'enum-' + key" [class]="color + ' ' + imageClass + (hasIcon? 'flex': '')">

        <i *ngIf="hasIcon" class="icon fa-duotone fa-2x fa-{{'icons.' + key.toLowerCase() | translate}} bg-[rgba(0,0,0,0.2)] text-white rounded-full p-4 w-16 h-16"></i>
        <span *ngIf="hasIcon" class="flex-1 text-2xl font-black leading-[4rem] py-2">{{ 'enums.'+key.toLowerCase() | translate }}</span>
        <span *ngIf="!hasIcon" class="">{{ 'enums.'+enum[key].toLowerCase() | translate }}</span>
        <i *ngIf="selectedValue === enum[key]" class="fa-duotone fa-check {{ hasIcon ? 'py-4' : ''}}"></i>
        <img class="image-{{imageClass}}" *ngIf="hasImages" [src]="getImageUrl(key)" alt="{{ key }}" />
    </label>
  </li>
</ul>
