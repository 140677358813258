import { Directive, Input, OnInit, OnDestroy, ElementRef } from '@angular/core';

@Directive({
  selector: '[countdown]'
})
export class CountdownDirective implements OnInit, OnDestroy {
  @Input('countdown') endTime: Date = new Date();
  private countdownInterval: any;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.startCountdown();
  }

  ngOnDestroy() {
    this.stopCountdown();
  }
  private startCountdown() {
    const countdownElement = this.elementRef.nativeElement;
    this.countdownInterval = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeDifference = this.endTime.getTime() - currentTime;
      if (timeDifference > 0) {
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        countdownElement.textContent = `${this.formatTime(hours)}${this.flashCharacter(':')}${this.formatTime(minutes)}${this.flashCharacter(':')}${this.formatTime(seconds)}`;
      } else {
        countdownElement.textContent = '00:00:00';
        this.stopCountdown();
      }
    }, 1000);
  }

  private flashCharacter(character: string): string {
    const currentTime = new Date().getTime();
    return currentTime % 2000 < 1000 ? character : ' ';
  }

  private stopCountdown() {
    clearInterval(this.countdownInterval);
  }

  private formatTime(time: number): string {
    return time < 10 ? `0${time}` : `${time}`;
  }
}
