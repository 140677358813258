import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'persian'
})
export class PersianPipe implements PipeTransform {
  transform(value: string | number | null): string {
    if (value === null || value === undefined) {
      return ' ';
    }

    if(typeof value === 'number') {
      value = value.toString();
    }

    const persianDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
    return value.replace(/\d/g, (digit) => persianDigits[parseInt(digit)]);
  }
}
