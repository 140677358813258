import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'second'
})
export class SecondPipe implements PipeTransform {
  transform(value: number): string {
    // if value is equal or less than 60 show with seconds, else show with minutes and seconds
    if (value <= 60) {
      return `${value} ثانیه`;
    } else {
      const minutes = Math.floor(value / 60);
      const seconds = value % 60;
      if (seconds === 0) {
        return `${minutes} دقیقه`;
      }
      return `${minutes} دقیقه و ${seconds} ثانیه`;
    }
  }
}
