import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-enum-radio-buttons',
  templateUrl: './enum-radio-buttons.component.html',
  styleUrls: ['./enum-radio-buttons.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnumRadioButtonsComponent),
      multi: true
    }
  ]
})
export class EnumRadioButtonsComponent implements ControlValueAccessor {
  @Input() enum: any;
  @Input('has-icon') hasIcon: boolean = false;
  @Input('has-images') hasImages: boolean = false;
  @Input('image-type') imageType: string = 'png';
  @Input('color') color: string = '';
  selectedValue: any;
  @Input('image-class') imageClass: string = '';
  @Input('two-columns') twoCols: boolean = false;

  get enumKeys(): string[] {
    return Object.keys(this.enum).filter(key => isNaN(Number(key)));
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Implement if needed
  }

  valueChangedEffects(value: any): void {
    this.onChange(value);
  }

  onValueChange(newValue: any): void {
    this.selectedValue = newValue;
    this.onChange(newValue);
    this.onTouched();
  }

  getImageUrl(key: string): string {
    return 'assets/images/enums/' + key.toString().toLowerCase() + '.' + this.imageType;
  }
}
