import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-workout-auto-complete',
  templateUrl: './workout-auto-complete.component.html',
  styleUrl: './workout-auto-complete.component.scss'
})
export class WorkoutAutoCompleteComponent {
  searchQuery = '';
  suggestions: any[] = [];
  @Input('workouts') allOptions: any;
  @Input('loading') loading: boolean = false;
  searchSubject: Subject<string> = new Subject();
  @Output() selectWorkout = new EventEmitter<any>();
  @Input('selected-workouts') selectedWorkouts: any = [];

  constructor() { }

  ngOnInit(): void {
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(value => {
      this.suggestions = this.filterSuggestions(value);
    });
  }

  onInput(event: any) {
    const value = event.target.value;
    this.searchSubject.next(value);
  }

  onClick() {
    this.searchSubject.next(this.searchQuery);
  }

  private filterSuggestions(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.allOptions.filter((option: any) => !this.selectedWorkouts.map((workout: any) => workout.english).includes(option.english))
    .filter((option: any) => option.persian.trim().replace(' ', '').toLowerCase().includes(filterValue.toLowerCase().trim().replace(' ',''))).slice(0, 5);
  }

  selectSuggestion(suggestion: any) {
    this.searchQuery = '';
    this.suggestions = [];
    this.selectWorkout.emit(suggestion);
  }
}
