import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-quiz-layout',
  templateUrl: './quiz-layout.component.html',
  styleUrl: './quiz-layout.component.scss'
})
export class QuizLayoutComponent {
  @Input() step: number = 0;
  @Input('total-steps') totalSteps: number = 100;
  @Input('show-progress') showProgress: boolean = true;

  get progress() {
    return this.step / this.totalSteps * 100 > 100 ? 100 : this.step / this.totalSteps * 100;
  }
}
