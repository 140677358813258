import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-enum-checkbox',
  templateUrl: './enum-checkbox.component.html',
  styleUrl: './enum-checkbox.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnumCheckboxComponent),
      multi: true
    }
  ]
})
export class EnumCheckboxComponent implements ControlValueAccessor {
  @Input() enum: any;
  @Input('has-images') hasImages: boolean = false;
  @Input('image-type') imageType: string = 'png';
  @Input('color') color: string = '';
  selectedValues: any[] = [];
  @Input('image-class') imageClass: string = '';
  @Input('two-columns') twoCols: boolean = false;

  get enumKeys(): string[] {
    return Object.keys(this.enum).filter(key => isNaN(Number(key)));
  }
  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    if(value === null || value === undefined) {
      return;
    }
    this.selectedValues.push(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // Implement if needed
  }
  valueChangedEffects(value: any): void {
    if(value === null || value === undefined) {
      return;
    }
    this.onChange(value);
  }
  onValueChange(newValue: any): void {
    if(newValue === null || newValue === undefined) {
      return;
    }
    const index = this.selectedValues.indexOf(newValue);
    if (index > -1) {
      this.selectedValues.splice(index, 1);
    } else {
      this.selectedValues.push(newValue);
    }
    this.onChange(this.selectedValues);
    this.onTouched();
  }
  getImageUrl(key: string): string {
    return 'assets/images/enums/' + key.toString().toLowerCase() + '.' + this.imageType;
  }
}
